.title-1CoGtdeh {
    font: bold 14px Microsoft Yahei;
    color: #2b2b2b;
    border-bottom: 1px solid #e5e5e5;
    height: 24px;
    margin-bottom: 10px
}

.title-1CoGtdeh span {
    float: left;
    border-bottom: 2px solid #bd0014;
    height: 22px;
}

.list-2nR8xnDf li {
    height: 35px;
    line-height: 35px;
    background: url(https://x2.ifengimg.com/fe/shank/content/s_11.75b2cabb.gif) no-repeat 5px 10px;
}

.red-2EkO7XYi {
    font-size: 18px;
    font-weight: bold;
    float: left;
    width: 16px;
    height: 23px;
    color: #ba2636;
    text-align: left;
    margin-right: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: oblique;
}

.black-2lhXEjmD {
    font-size: 18px;
    font-weight: bold;
    float: left;
    width: 16px;
    height: 23px;
    color: #666666;
    text-align: left;
    margin-right: 10px;
    font-family: Arial, Helvetica, sans-serif;
}
