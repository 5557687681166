* {
    font-family: 'arial', 'microsoft yahei';
}

.col-i6wP7Dk- {
    position: relative;
    width: 1000px;
    margin: 0 auto;
    padding-top: 20px;
}

.ifengLogo-3sbIYAy1, .topic-mXc_m4C6 {
    float: left;
    padding-right: 20px;
}

.ifengLogo-3sbIYAy1 span {
    display: inline-block;
    width: 139px;
    height: 52px;
    background: url(https://x2.ifengimg.com/fe/shank/content/logo.d76a1c3b.jpg) no-repeat;
}

.topic-mXc_m4C6 span {
    display: inline-block;
    width: 312px;
    height: 55px;
    background: url(https://x2.ifengimg.com/fe/shank/content/pic01.af0994bb.jpg) no-repeat;
}

.tip404-25h9VLyB {
    position: relative;
    padding: 45px 0 0 0;
    height: 320px;
    float: left;
    width: 450px
}

.tip404-25h9VLyB h1 {
    font: normal 26px/26px Microsoft Yahei;
    color: #4c4c4c;
    padding-bottom: 8px;
}

.tip404-25h9VLyB ul li {
    font-size: 14px;
    line-height: 1.6em;
    color: #aaa;
    background: url(https://x2.ifengimg.com/fe/shank/content/icon.02b0d0af.gif) no-repeat 0 9px;
    padding-left: 15px;
}

.tip404-25h9VLyB h4 {
    padding: 11px 0 30px;
}

.tip404-25h9VLyB h4 span {
    display: inline-block;
    width: 162px;
    height: 34px;
    background: url(https://x2.ifengimg.com/fe/shank/content/gaijin.1e84761e.png) no-repeat;
}

.leftLink-2kgsgFvi {
    width: 445px;
    float: left;
    height: 356px;
    margin-right: 105px;
    overflow: hidden;
    margin-top: 15px;
    background: url(https://x2.ifengimg.com/fe/shank/content/404.bb5349ea.gif) no-repeat;
}

.img404-7zdjrjG5 span {
    display: inline-block;
    width: 250px;
    height: 106px;
    background: url(https://x2.ifengimg.com/fe/shank/content/404.942727d6.png) no-repeat;
}

.tip-2cAa1I7y span {
    font: normal 16px/24px Arial, Helvetica, sans-serif;
    color: #000;
}

.dataBox-BI87wF-O {
    width: 998px;
    height: 350px;
    background: #f7f7f7;
    border: 1px solid #e8e8e8;
    margin: 0 auto;
    position: relative;
    margin-bottom: 20px;
}

.boxL-3VpPHlNh {
    float: left;
    width: 400px;
    padding: 18px 40px 0 40px;
}

.boxR-2zGy0e_J {
    float: right;
    width: 400px;
    padding: 18px 40px 0 40px;
}

.footer-1cQXwCIe {
    width: 1000px;
    margin: 0 auto;
}

.foot_link-1r43jmZW {
    position: relative;
    height: 28px;
    padding-top: 15px;
    overflow: hidden;
    color: #000;
    line-height: 28px;
    font-size: 12px
}

.foot_link-1r43jmZW a {
    color: #000;
}

.footL-2PvSJ9sH {
    float: left;
}

.footR-3dLefugo {
    width: 200px;
    float: right
}

.footR-3dLefugo ul {
    overflow: hidden;
}

.footR-3dLefugo ul li {
    height: 28px;
    line-height: 28px;
    overflow: hidden;
    float: left;
}

.bg001-2Hw-41-i {
    background: url(https://x2.ifengimg.com/fe/shank/content/bg001.74d7502f.gif) no-repeat left 9px;
    padding-left: 19px;
}

.bg002-n7aGuK9b {
    background: url(https://x2.ifengimg.com/fe/shank/content/bg002.68c300b5.gif) no-repeat left 9px;
    padding-left: 17px;
    margin-left: 18px;
}

.list-2K5uU18- {
    text-align: center;
}

.link-1C2aIkOw {
    color: #000 !important;
    font-size: 12px;
    text-decoration: none;
    padding: 0 7px;
    border-right: 1px solid #000;
}

.link-1C2aIkOw:hover {
    color: #f54343 !important;
    text-decoration: underline;
}

.link-1C2aIkOw:first-child {
    padding-left: 0;
}

.link-1C2aIkOw:last-child {
    border: 0;
}

.copyright-1IRh3xb0 {
    font-family: Arial, '宋体';
    color: #666;
    height: 28px;
    text-align: center;
    overflow: hidden;
    line-height: 28px
}

.copyright-1IRh3xb0 span {
    padding-right: 12px;
    font-size: 12px;
}
