.pao_mid-3PiRjGBU {
    width: 445px;
    height: 266px;
    border: 1px solid #cfcfcf;
    float: left;
    margin: 47px 102px 0 0
}
.pao_mid-3PiRjGBU [ifeng_ui_pc_sliders-dots] {
    left: 145px;
}
.pao_mid-3PiRjGBU [ifeng_ui_pc_sliders-title] {
    height: 32px;
    font: 14px '微软雅黑';
    line-height: 32px;
    color: #fff
}
.pao_mid-3PiRjGBU [ifeng_ui_pc_sliders-title]:hover {
    color: #fff;
    text-decoration: underline;
}
.box-2b7T42r_ {
    width: 418px;
    height: 235px;
    overflow: hidden;
    position: relative;
    margin: 15px auto 0;
    background: #ccc;
}
.dot-1kQc-dtx {
    float: left;
    margin-right: 3px;
    display: inline;
    cursor: pointer;
    background: #fff;
    width: 7px;
    height: 7px;
    text-indent: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 6px;
}
.current-7JOwQBk8 {
    background: #f34443;
}
